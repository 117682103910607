import React, { useEffect, useState, useContext } from "react";
import { generateSlug } from "./Helper";
import DOMPurify from "dompurify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useParams } from "react-router-dom";
import DetailsShimmer from "./DetailsShimmer";
import { useMediaQuery } from "react-responsive";
import { BlogsContext } from "../contexts/BlogsContext";
import "../css/blogdetails.css";

function BlogDetails() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { blogs } = useContext(BlogsContext);
  const [blogData, setBlogData] = useState(null);

  const { heading } = useParams();
  const [isBannerLoaded, setIsBannerLoaded] = useState(false);

  useEffect(() => {
    const filteredBlog = blogs.find(
      (item) => generateSlug(item.HEADING) === heading
    );
    setBlogData(filteredBlog || null); // Set blogData to null if no matching blog is found
  }, [heading, blogs]);

  function goToTop() {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      {blogData ? (
        <div className="blog-details-wrapper">
          <div className="blog-details-box">
            <div className="first-block">
              <div>
                <h1>{blogData["HEADING"]}</h1>
              </div>
              <div>
                <div
                  id="blog-description"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(blogData["INTRO"]),
                  }}
                  style={isBannerLoaded ? {} : { display: "none" }}
                />
              </div>
              <br />
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: blogData["CREATOR"],
                  }}
                />

                <p id="blogDate">Published On: {blogData["CREATEDON"]}</p>
              </div>

              <img
                src={blogData["BANNER"]}
                alt={blogData["HEADING"]}
                onLoad={() => setIsBannerLoaded(true)}
              />
              <Skeleton
                height={300}
                width={isMobile ? 310 : 800}
                style={!isBannerLoaded ? {} : { display: "none" }}
              />
              {/* <br /> */}
              {blogData.DETAILS.map((subitem, subindex) => (
                <div
                  key={subindex}
                  style={isBannerLoaded ? {} : { display: "none" }}
                >
                  <div
                    id="blog-subheading"
                    dangerouslySetInnerHTML={{
                      __html:
                        subitem && subitem.SUBHEADING
                          ? DOMPurify.sanitize(subitem.SUBHEADING)
                          : "",
                    }}
                  />
                  <br />
                  <div className="details-description">
                    {subitem && subitem.DESCRIPTION
                      ? subitem.DESCRIPTION.split("\\n\\n").map(
                          (line, lineIndex) => (
                            <div key={lineIndex}>
                              <div
                                id="blog-info"
                                dangerouslySetInnerHTML={{
                                  __html: line,
                                }}
                              />
                              <br />
                            </div>
                          )
                        )
                      : null}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="blog-cards-wrapper">
            <div className="blog-cards-container">
              {blogs.map((blog, index) => {
                if (!blog) {
                  return null;
                }
                return blog.HEADING !== blogData.HEADING ? (
                  <Link
                    to={`/blogs/${generateSlug(blog.CATEGORY)}/${generateSlug(
                      blog.HEADING
                    )}`}
                    className="blog-card"
                    key={index}
                    onClick={goToTop}
                  >
                    <img src={blog.BANNER} alt={blog.HEADING} />
                    <p>{blog.CATEGORY}</p>
                    <p>{blog.HEADING}</p>
                  </Link>
                ) : null;
              })}
            </div>
          </div>
        </div>
      ) : (
        <DetailsShimmer />
      )}
    </>
  );
}

export default BlogDetails;
