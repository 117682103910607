import { createContext, useEffect, useState } from "react";
import { getHuttiBlogs } from "../Connection";

export const BlogsContext = createContext();

export const BlogsProvider = ({ children }) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    getHuttiBlogs((res) => {
      setBlogs(res);
    });
  }, []);

  return (
    <BlogsContext.Provider value={{ blogs }}>{children}</BlogsContext.Provider>
  );
};
