import React, { useEffect } from "react";
import Banner from "./Banner";
import Stores from "./Stores";
import Features from "./Features";
import Steps from "./Steps";
import "../css/main.css";
import Aos from "aos";
import { Helmet } from "react-helmet-async";
import "aos/dist/aos.css";

function Website() {
  const canonicalUrl = `https://hutti.in/`;

  useEffect(() => {
    Aos.init({
      duration: 1000,
      disable: "mobile",
    });
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Banner />
      <Stores />
      <Features />
      <Steps />
    </>
  );
}

export default Website;
