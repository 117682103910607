export const PLAY_STORE =
"https://play.google.com/store/apps/details?id=com.shop.hutti";

// export const APPS = [
//   {
//     subdomain: "hutti",
//     app: MainRouter,
//     main: true,
//   },
//   {
//     subdomain: "tg",
//     app: TGPageRouter,
//     main: false,
//   },
// ];

export const TELEGRAM = 'tg';
export const APP ='app';
export const COUNTDOWN_TIMER = 60;
export const SUCCESS = 'success';
export const FAILURE = 'failure;'


