import React, { useEffect, useState } from "react";
import hutti_logo from "../images/hutti-unbackground.png";
import "../css/navbar.css";
import { Link, useLocation } from "react-router-dom";
function Navbar() {

  const [active, setActive] = useState('home');

  const location = useLocation();

  useEffect(() => {
    if(location.pathname === '/'){
      setActive('home');
    }
    else if (location.pathname === '/about-hutti-cashback'){
      setActive('about');
    }
    else if(location.pathname === '/blogs'){
      setActive('blogs');
    }
    else{
      setActive('other');
    }
  },[location.pathname])

  function goToTop(){
    window.scroll({
      top : 0,
      behavior : 'smooth'
    });
  }

  return (
    <div className="navbar-wrapper">
      <Link to="/" className="nav-logo" onClick={goToTop}>
        <img src={hutti_logo} alt="" id="hutti-logo" />
      </Link>
      <div className="nav-tab">
        <Link to="/" className={`nav-link  ${active === 'home' ? 'active' : ''}`} onClick={goToTop}>
          Home
        </Link>
        <Link to="/about-hutti-cashback" className={`nav-link ${active  === 'about' ? 'active' : ''}`} onClick={goToTop}>
          About Us
        </Link>
        <a href="/blogs" className={`nav-link desktop-blog-link ${active  === 'blogs' ? 'active' : ''}`} onClick={goToTop} target="_blank">
          Blogs
        </a>
        <Link to="/blogs" className={`nav-link mobile-blog-link  ${active === 'blogs' ? 'active' : ''}`} onClick={goToTop}>
          Blogs
        </Link>
      </div>
    </div>
  );
}

export default Navbar;
