import React, { useEffect, useState, useRef } from "react";
import Loader from "./Loader";
import logo from "../images/resend-logo.png";
import "../css/verifyotp.css";
import { generateOtp, reGenerateOtp, verifyOtp } from "../Connection";
import { COUNTDOWN_TIMER, SUCCESS } from "../utils/constants";
import { States } from "../utils/states";

function VerifyOtp({ mobile, isOtpVerified }) {
  const [orderId, setOrderId] = useState(null);
  const [otp, setOtp] = useState("");
  const [otpState, setOtpState] = useState(States.UNDEFINED);
  const [otpError, setOtpError] = useState("");
  const isOtpSentRef = useRef(false);
  const [countdown, setCountdown] = useState(60);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown]);

  useEffect(() => {
    if (!isOtpSentRef.current && orderId === null) {
      isOtpSentRef.current = true;
      generateOtp("91" + mobile, (result) => {
        if (result.status === "success") {
          setOrderId(result.orderId);
        }
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOtp({ ...otp, [name]: value });
    if (value.length === 6) {
      setOtp(value);
    }
  };

  const resendOtp = (e) => {
    setCountdown(COUNTDOWN_TIMER);
    e.preventDefault();
    reGenerateOtp(orderId, (result) => {});
  };

  const submitOtp = () => {
    setOtpError("");
    setOtpState(States.OTP_VERIFICATION_INIT);
    if (otp.length === 6)
      verifyOtp(mobile, orderId, otp, (output) => {
        setOtpState(States.OTP_VERIFICATION_COMPLETED);
        if (output.status === SUCCESS && output.isOTPVerified) {
          setLoader(true);
          isOtpVerified(true);
        } else {
          setOtpError("Wrong OTP");
        }
      });
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <div className="otp-wrapper">
        <div className="otp-container">
          <div className="otp-box-up">
            <div className="otp-header">
              <div>
                <p>Verify the mobile number</p>
              </div>
              <div>
                <p id="info_msg">Enter the 6 digit code sent to</p>
                <p>{mobile}</p>
              </div>
            </div>
            <div>
              <label htmlFor="otp-input">Enter verification code here</label>
              <input
                type="tel"
                name="otp"
                id="otp-input"
                maxLength="6"
                onChange={handleChange}
              />
              {otpError.length > 0 && (
                <p className="wrongOtp" style={{ marginTop: 4 }}>
                  {otpError}
                </p>
              )}
            </div>
            <div className="buttonBox">
              <button disabled={otp.length !== 6} onClick={submitOtp}>
                {[States.UNDEFINED, States.OTP_VERIFICATION_COMPLETED].includes(
                  otpState
                )
                  ? "Submit"
                  : "Please wait..."}
              </button>
            </div>
          </div>
          <div className="resend-box">
            {countdown > 0 ? (
              <p>Didn't receive the code? Resend in {countdown} sec</p>
            ) : (
              <>
                <div>
                  <p>Didn't get the code</p>
                </div>
                <div onClick={resendOtp}>
                  <img src={logo} alt="" />
                  <p> Resend on SMS</p>
                </div>
              </>
            )}
          </div>

          <div className="tnc-box">
            <p>
              By signing in, I agree to{" "}
              <a href="https://hutti.in/terms" target="_blank" rel="noreferrer">
                Terms & Conditions
              </a>{" "}
              and{" "}
              <a
                href="https://hutti.in/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy.
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifyOtp;
