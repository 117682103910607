import React, { useContext, useEffect, useRef, useState } from "react";
import GetUserMobileForm from "./LoginForm";
import VerifyOtp from "./VerifyOtp";
import { States } from "../utils/states";
import { onAuthStateChanged } from "firebase/auth";
import { auth, fetchProgramData, getRecentClick, signInUser, submitCLick } from "../firebase";
import { Timestamp } from "firebase/firestore";
import { HashContext } from "../contexts/HashContext";
import Loader from "./Loader";
import { SUCCESS } from "../utils/constants";

function Login() {

  const hash = useContext(HashContext);
  
  const [state, setState] = useState(States.UNDEFINED);
  const [loader, setLoader] = useState(false);
  const mobileRef = useRef(null);
  const userRef = useRef(null);
  const programDataRef = useRef(null);

  useEffect(() => {
    // if (state === States.UNDEFINED)  signOut(auth);
    if (state === States.LOGGEDIN && programDataRef.current !== null) {
  
      let click = {
        'uid': userRef.current.uid,
        'timestamp': Timestamp.now(),
        'programId': programDataRef.current.ID,
      }
      //check if we have recent click generated (24 hours)
      getRecentClick(click.programId, existingClick => {
        let url = '';
        if (existingClick === null) {
          submitCLick(click, clickId => {
            if (clickId !== null) {
              url = programDataRef.current.DEEPLINK.replaceAll("{gid}", clickId);
              openBrowser(url);
            }
            else{
              setLoader(true);
            } 
          })
        } else {
          //check if date has changed
          let prevTimestamp = existingClick.data().timestamp.toDate();
          prevTimestamp.setHours(0,0,0,0);

          let currTimestamp = click.timestamp.toDate();
          currTimestamp.setHours(0,0,0,0);
          if (prevTimestamp.getTime() !== currTimestamp.getTime()) {
            //create new click
            click.clickId = existingClick.id;
            submitCLick(click, clickId => {
              if (clickId !== null) {
                url = programDataRef.current.DEEPLINK.replaceAll("{gid}", existingClick.id);
                openBrowser(url);
              }
            })
          } else {
            url = programDataRef.current.DEEPLINK.replaceAll("{gid}", existingClick.id);
            openBrowser(url);
          }
        }
      })

    }
  }, [state])

  const openBrowser = (url) => {
    window.open(url, '_self');
  }

  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {;
      //delayed setState
      if (currentuser !== null) {
        userRef.current = currentuser;
        if (programDataRef.current === null) {
          fetchProgramData(hash, data => {
            programDataRef.current = data;
            setState((prevState) => prevState = States.LOGGEDIN);
          })
        } else {
          setState((prevState) => prevState = States.LOGGEDIN);
        }
      } else {
        setState(States.LOGOUT);
      }
      
    });

    return () => {
      unsubscribe();
    };

  }, []);

  function getPhoneNumber(val) {
    setState(States.LOGIN_INIT);
    mobileRef.current = val;
  }

  const signIn = (isOtpVerified) => {
  
    if (isOtpVerified) {
      //signIn
      signInUser(mobileRef.current, res => {
        if (res === SUCCESS) {
          if (programDataRef.current === null) {
            fetchProgramData(hash, data => {
              programDataRef.current = data;
              setState(prevState => prevState = States.LOGGEDIN);
            })
          }
        }
      })
    }
  }

  if (state === States.LOGOUT) {
    return (
      <>
        <GetUserMobileForm getPhoneNumber={getPhoneNumber} />
      </>
    );
  } else if (state === States.LOGIN_INIT && !loader) {
    return (
      <>
        <VerifyOtp mobile={mobileRef.current} isOtpVerified={signIn} />
      </>
    );
  } 
  else if(state === States.LOGIN_INIT && loader){
    return<Loader/>
  }
  else {
      return<Loader/>
  }
}

export default Login;
