import React, { useState } from "react";
import hutti_logo from "../images/hutti-unbackground.png";
import gplay from '../images/googleplay_badge.png'
import "../css/login.css";
import { PLAY_STORE } from "../utils/constants";

function GetUserMobileForm({getPhoneNumber}) {

  const [mobile, setMobile] = useState("");

  const handleMobileInput = (e) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      if (value.length <= 10) {
        setMobile(value);
      }
    }
  };

  const sendOtp = (mobileNumber) => {

    if (mobileNumber.match(/\d{10}$/)) {
      getPhoneNumber(mobile);
    } else {
      // setError("Please enter valid mobile number");
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="login-box-up">
          <div className="logo-box">
            <img src={hutti_logo} alt="" />
          </div>
      <div>
        <p id="">Sign in to your account</p>
      </div>
          <div>
            <input
              type="text"
              name="mobile"
              value={mobile}
              id="mobile-input"
              placeholder="Enter 10 digit mobile number"
              maxLength="10"
              onChange={handleMobileInput}
            />
          </div>
          <div className="buttonBox">
            <button disabled={mobile.length !== 10} onClick={() => sendOtp(mobile)}>
              Send Verification Code
            </button>
          </div>
        </div>
        <div className="track-box">
          <div>
            <p>Track and redeem all your cashback easily on Hutti app. Download now!</p>
          </div>
          <a href= {PLAY_STORE + "&referrer=utm_medium%3Dtg.hutti.in"} id="buttonBox-download" target="_blank" rel="noreferrer">
            <img src={gplay} alt="" />
          </a>
        </div>
        <div className="tnc-box">
          
          <p>By signing in, I agree to <a href="https://hutti.in/terms" target="_blank" rel="noreferrer">Terms & Conditions</a> and <a href="https://hutti.in/privacy" target="_blank" rel="noreferrer">Privacy Policy.</a></p>
           
        </div>
      </div>
    </div>
  );
}

export default GetUserMobileForm;
