import React from 'react'
import video from '../Video/huttivid.mp4';
import '../css/video.css'

function Video() {

  return (
    <div className='video-wrapper'>
      <video src={`${video}#t=0.001`} controls preload='metadata'></video>
      
    </div>
  )
}

export default Video
