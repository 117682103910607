import React, { useState } from "react";
import { Link } from "react-router-dom";
import Video from "./Video";
import hutti_logo from "../images/hutti-unbackground.png";
import mail from "../images/mail.png";
import location from "../images/location_2.png";
import badge from "../images/googleplay_badge.png";
import instagram from "../images/instagram.png";
import telegram from "../images/telegram.png";
import facebook from "../images/facebook.png";
import close from "../images/close.png";
import "../css/footer.css";

function Footer() {
  const currentYear = new Date().getFullYear();

  const [showVideo, setShowVideo] = useState(false);

  const openVideoPopup = () => {
    setShowVideo(true);
    document.body.style.overflowY = "hidden";
  };

  const closeVideoPopup = () => {
    setShowVideo(false);
    document.body.style.overflowY = "scroll";
  };

  function goToTop() {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      <div className="footer-wrapper">
        <div className="footer-container-one">
          <div className="footer-box one">
            <img src={hutti_logo} alt="" id="hutti_logo_footer" />
            <a
              href="https://play.google.com/store/apps/details?id=com.shop.hutti&referrer=utm_source%3Dgoogle%26utm_medium%3Dwebiste%26utm_campaign%3Dhutti"
              className="footer-links"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={badge} alt="" id="badge" />
            </a>

            <p id="follow-us">Follow Us</p>
            <div className="social-media">
              <a
                href="https://t.me/+znVE4Iudq5Q4ODc1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={telegram} alt="Telegram" />
              </a>
              <a
                href="https://www.instagram.com/hutti_app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="Instagram" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61567431245797"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Facebook" />
              </a>
            </div>
          </div>
          <div className="footer-box">
            <p className="footer-section-title">ABOUT</p>
            <Link
              to="/about-hutti-cashback"
              className="footer-links"
              onClick={goToTop}
            >
              About Us
            </Link>
            <a href="/blogs" className="footer-links" onClick={goToTop} target="_blank">
              Blogs
            </a>
            <p onClick={openVideoPopup} className="footer-links">
              How to earn Cashback with Hutti?
            </p>
            <a href="/terms" className="footer-links" target="_blank">
              Terms & Conditions
            </a>
            <a href="/privacy" className="footer-links" target="_blank">
              Privacy Policy
            </a>
          </div>
          <div className="footer-box">
            <p className="footer-section-title">CONTACT</p>
            <div className="contact-box">
              <img src={mail} alt="" />
              <a
                href="mailto:support@hutti.in"
                id="mail"
                className="footer-links"
              >
                support@hutti.in
              </a>
            </div>
            <div className="contact-box">
              <img src={location} alt="" />
              <p className="footer-links address">New Delhi - 110058</p>
            </div>
          </div>
        </div>
        <div className="footer-container-two">
          <ul>
            <p id="copyright">
              All Rights Reserved |{" "}
              <span>
                <i className="fa fa-copyright" aria-hidden="true"></i> Copyright
              </span>{" "}
              <span id="companyname">@Hutti&nbsp;</span>
              <span>{currentYear}&nbsp;</span>
            </p>
          </ul>
        </div>
      </div>
      {showVideo && (
        <div className="video-popup">
          <div className="video-popup-content">
            <p onClick={closeVideoPopup} className="close-popup">
              <img src={close} alt="" />
            </p>
            <Video />
          </div>
        </div>
      )}
      <div className="footer-wrapper-mobile">
        <div className="footer-container-one">
          <div>
            <div className="footer-box">
              <p className="footer-section-title">ABOUT</p>
              <Link
                to="/about-hutti-cashback"
                className="footer-links"
                onClick={goToTop}
              >
                About Us
              </Link>
              <Link to="/blogs" className="footer-links" onClick={goToTop}>
                Blogs
              </Link>
              <p onClick={openVideoPopup} className="footer-links">
                How to earn Cashback with Hutti?
              </p>
              <Link to="/terms" className="footer-links" onClick={goToTop}>
                Terms & Conditions
              </Link>
              <Link to="/privacy" className="footer-links" onClick={goToTop}>
                Privacy Policy
              </Link>
            </div>
            <div className="footer-box">
              <p className="footer-section-title">CONTACT</p>
              <div className="contact-box">
                <img src={mail} alt="" />
                <a
                  href="mailto:support@hutti.in"
                  id="mail"
                  className="footer-links"
                >
                  support@hutti.in
                </a>
              </div>
              <div className="contact-box">
                <img src={location} alt="" />
                <p className="footer-links">New Delhi - 110058</p>
              </div>
            </div>
          </div>
          <div>
            <div className="footer-box one">
              <img src={hutti_logo} alt="" id="hutti_logo_footer" />
              <a
                href="https://play.google.com/store/apps/details?id=com.shop.hutti&referrer=utm_source%3Dgoogle%26utm_medium%3Dwebiste%26utm_campaign%3Dhutti"
                className="footer-links"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={badge} alt="Badge" id="badge" />
              </a>

              <p id="follow-us">Follow Us</p>
              <div className="social-media">
                <a
                  href="https://t.me/+znVE4Iudq5Q4ODc1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={telegram} alt="Telegram" />
                </a>
                <a
                  href="https://www.instagram.com/hutti_app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagram} alt="Instagram" />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61567431245797"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook} alt="Facebook" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-container-two">
          <ul>
            <p id="copyright">
              All Rights Reserved |{" "}
              <span>
                <i className="fa fa-copyright" aria-hidden="true"></i> Copyright
              </span>{" "}
              <span id="companyname">@Hutti&nbsp;</span>
              <span>{currentYear}&nbsp;</span>
            </p>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Footer;
