import { initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import {
    doc,
    Timestamp,
    addDoc,
    collection,
    getFirestore,
    getDocs,
    query,
    where,
    setDoc,
} from "firebase/firestore";

import { child, get, ref, getDatabase } from "firebase/database";
import { getFirebaseToken } from "./Connection";
import { FAILURE, SUCCESS } from "./utils/constants";

const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const projectId = process.env.REACT_APP_FIREBASE_PROJECTID;
const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID;
const appId = process.env.REACT_APP_FIREBASE_APPID;
const databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL;

const firebaseConfig = {
    apiKey: apiKey,
    authDomain: authDomain,
    projectId: projectId,
    storageBucket: storageBucket,
    messagingSenderId: messagingSenderId,
    appId: appId,
    databaseURL: databaseURL,
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const firestore = getFirestore(app);
const rtdb = ref(getDatabase(app));
export default app;

export const fetchProgramData = async (hash, callBack) => {
    get(child(rtdb, `TG_OFFERS_AND_DEALS/${hash}`)).then((snapshot) => {
        // console.log(snapshot.val());
        if (snapshot.exists()) {
            callBack(snapshot.val());
        } else {
            callBack(null);
        }
    });
};

export const submitCLick = async (click, callback) => {
    try {
        const docRef = await addDoc(collection(firestore, "CLICK_DB"), click);
        callback(docRef.id);
    } catch (e) {
        // console.error("Error adding document: ", e);
        callback(null);
    }
};

const createProfile = async (profile, callBack) => {

    const docRef = doc(firestore, 'UID_DB', auth.currentUser.uid);
    setDoc(docRef, profile).then(() => {
      callBack(true);
    }).catch(() => {
      callBack(false);
    })
}

export const getRecentClick = async (programId, callBack) => {
    if (programId !== undefined) {
        const _timestamp = Timestamp.fromDate(
            new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
        );

        const collRef = collection(firestore, "CLICK_DB");
        const q = query(
            collRef,
            where("uid", "==", auth.currentUser.uid),
            where("programId", "==", programId),
            where("timestamp", ">", _timestamp)
        );

        const snapshots = await getDocs(q);

        if (snapshots.size > 0) {
            callBack(snapshots.docs.at(0));
        } else {
            callBack(null);
        }
    }
    else {
        callBack(null);
    }
};

export const signInUser = (mobile, callBack) => {
    getFirebaseToken(mobile, (token) => {
        signInWithCustomToken(auth, token)
            .then((userCredential) => {
                if (userCredential._tokenResponse.isNewUser) {
                    //create doc in UID_DB
                    let profile = {
                        'mobile': mobile,
                        'timestamp': Timestamp.now(),
                        'utm_medium': 'tg.hutti.in',
                        'utm_campaign': 'tg'
                    }
                    createProfile(profile, (res) => res ? callBack(SUCCESS) : callBack(FAILURE))
                } else {
                    callBack(SUCCESS);
                }
            })
            .catch((err) => {
                console.log(err.code);
                callBack(FAILURE);
            });
    });
};
