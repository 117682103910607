import { useContext, useState } from "react";
import { generateSlug } from "./Helper";
import BlogsShimmer from "./BlogsShimmer";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { BlogsContext } from "../contexts/BlogsContext";
import "../css/blogs.css";


function Blogs() {
  const [isBannerLoaded, setIsBannerLoaded] = useState(false);

  const {blogs} = useContext(BlogsContext);

  function goToTop() {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      {blogs.length === 0 ? (
        <BlogsShimmer />
      ) : (
        <div className="blogs-wrapper">
          <div className="blogs-container">
            {blogs.map((item, index) => {
              if (!item) {
                return null;
              }

              return (
                <Link
                  to={`/blogs/${generateSlug(item.CATEGORY)}/${generateSlug(item.HEADING)}`}
                  className="first-box"
                  key={index}
                  onClick={goToTop}
                >
                  <img
                    src={item.BANNER}
                    alt={item.HEADING}
                    onLoad={() => setIsBannerLoaded(true)}
                  />
                  <Skeleton
                    height={300}
                    style={!isBannerLoaded ? {} : { display: "none" }}
                  />
                  <div className="description-box">
                    <div className="description-box-heading">
                      <p>{item.HEADING}</p>
                      <h3>{item.CATEGORY}</h3>
                    </div>
                    <div className="description-box-date">
                      <p>Published On: {item.CREATEDON}</p>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default Blogs;
