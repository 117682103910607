import axios from "axios";

const URL = "https://api.connecttocms.in";
// const URL = "http://localhost:84";
const URL_ARYO = "https://aryoconnect.in/";


export async function generateOtp(mobile, callBack) {
  const result = await axios.post(URL_ARYO + "sendOtp", { mobile: mobile , isTeam : true });
  callBack(result.data);
  // callBack({ status: 'success', orderId: '1703784067285' })
}

export async function reGenerateOtp(orderId, callBack) {
  const result = await axios.post(URL_ARYO + "resendOtp", { orderId: orderId });
  callBack(result.data);
  // callBack({status: 'success', orderId: '1703784067285'})
}

export async function verifyOtp(mobile, orderId, otp, callBack) {
  const result = await axios.post(URL_ARYO + 'verifyOtp', {
    'mobile': mobile,
    'orderId': orderId,
    'otp': otp
  })
  callBack(result.data);
  // callBack({ 'status': 'success', 'isOTPVerified': true });
  // callBack({ 'status': 'success', 'isOTPVerified': false });
}

export const getHuttiBlogs = async (callback) => {
  const result = await axios.get(URL + "/hutti/getHuttiBlogs");
  callback(result.data);
};

export async function getFirebaseToken(mobile, callBack) {
  const result = await axios.get(URL + "/hutti/getHuttiCustomToken/?mobile=" + mobile)
  callBack(result.data)
}
